import React, { Children, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import aos from 'aos';

import Header from './header';
import Carousel from './carousel';
import Footer from './footer';

// Init the animation for all pages that are inside AppWrapper

const AppWrapper = (props) => {

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    aos.init({ offset: 250, duration: 700 });
  }, [])
  
  const {
    children, className, currentPath,
    carousel, backgrounds, bannersLeft, bannerRight, autoPlay,
    disableFooter,
  } = props;

  return (
    <div className="app-wrapper">
      <Header {...{ currentPath }} />
      {carousel && (<Carousel {...{ backgrounds, bannersLeft, bannerRight, autoPlay }} />)}

      <section
        className={`wrapper-content${className ? ` ${className}` : ''}`}
      >

        {
          Children.toArray(children)
        }
      </section>

      {!disableFooter && (<Footer />)}
    </div>
  );
};

AppWrapper.defaultProps = {
  carousel: false,
  autoPlay: false,

  disableFooter: false,
};

AppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string, // eslint-disable-line react/require-default-props
  currentPath: PropTypes.string.isRequired,

  carousel: PropTypes.bool,
  backgrounds: PropTypes.array, // eslint-disable-line react/require-default-props
  bannersLeft: PropTypes.array, // eslint-disable-line react/require-default-props
  bannerRight: PropTypes.node, // eslint-disable-line react/require-default-props
  autoPlay: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),

  disableFooter: PropTypes.bool,
};

export default AppWrapper;
