import React, { useEffect, useState } from "react";
import TextTruncate from "react-text-truncate";
import { Link } from "react-router-dom";
import moment from "moment";
import kebabCase from "lodash/kebabCase";
import { getFetchNewsWidgetData } from "../../api/getData";

const NewsWidget = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const result = await getFetchNewsWidgetData();
    if (result.items) setNews(result.items);
  };

  return (
    <div>
      {news.map((item) => {
        const { fields, sys } = item;
        const year = new Date(fields.date).getFullYear();
        return (
          <article key={sys.id} className="latest-news top-news">
            <em>{moment(fields.date).format("Do MMMM YYYY")}</em>
            <Link to={`/news/${year}/${kebabCase(fields.title)}`}>
              <TextTruncate line={3} truncateText="…" text={fields.body} />
            </Link>
          </article>
        );
      })}
    </div>
  );
};

// function mapStateToProp(state) {
//   return {
//     news: state.get('home').news,
//   };
// }

// export function mapDispatchToProps(dispatch) {
//   return bindActionCreators(
//     { fetchNewsWidget },
//     dispatch
//   );
// }

// export default connect(mapStateToProp, mapDispatchToProps)(NewsWidget);
export default NewsWidget;
