import { Helmet } from 'react-helmet';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import HiddenEmail from '../../components/HiddenEmail';

import AppWrapper from '../AppWrapper';

import './styles.scss';

// NOTE: callback form is temporary hidden
// import CallbackForm from './callback-form';

import ImgFindUsMap from './images/img-find-us-map_tmp.png';

const ContactUs = () => (
  <AppWrapper
    className="contact-us-page"
    currentPath="/contact-us"

    carousel
    bannersLeft={[carouselBannerLeft]}
    bannerRight={carouselBannerRight}
  >

    <Helmet>
      <title>Robin Russell &amp; Associates | Contact Us</title>
      <meta name="description" content="Subdivision construction services - Queensland" />
    </Helmet>

    <div className="container" role="appWrapper">
      <section className="contact-info">
        <Row className="row--grid">
          <Col xs={12} sm={10}>
            <p>
              <strong><span className="text--color-red"><u>PLEASE NOTE:</u></span> &nbsp;Robin Russell &amp; Associates is well set
                up to remain fully functional in a locked-down Covid-19 environment. <a href="https://robrus.com.au/news/2020/rra-operations-unaffected-by-covid-19">More info &gt;</a></strong><br /><br />
            </p>
            <p>
              We are not simply design consultants and we are not contractors.
              We are <strong>Consulting Electrical Engineers</strong>,
              delivering quality subdivision electrical services, with full
              professional support. Clients end up with the lowest overall cost
              for design + construction.
            </p>
            <br />
          </Col>
          <Col xs={12} sm={2}>
            <h3 className="text-right hidden-xs"><br />Find us</h3>
          </Col>
        </Row>

        {/* General Enquiries */}
        <Row className="row--grid">
          <Col xs={12} sm={2}>
            <strong className="text-uppercase">General Enquiries</strong>
          </Col>

          <Col xs={12} sm={5} className="general-enquiries">
            <strong>Robin Russell & Associates, Brisbane Office</strong>
            <ul>
              <li>204/6 Babarra St, Stafford QLD 4053</li>
              <li>p. <a className="text--color-default" href="tel:0738725555">(07) 3872 5555</a></li>
              <li>e.
                &nbsp;<HiddenEmail mailto="rr@robrus.com.au" />
              </li>
            </ul>
            <ul>
              <li>abn. 78 010 589 661</li>
              <li>rpeq. 1546 (Robin Russell)</li>
            </ul>
          </Col>

          <Col xs={12} sm={5} className="find-us-location">
            <h3 className="text-center visible-xs">Find us</h3>
            <div>
              <a href="https://goo.gl/maps/tbCVfTp766K2" target="blank">
                <img
                  className="img-responsive"
                  src={ImgFindUsMap}
                  alt="Robin Russell & Associates, Brisbane Office location"
                />
              </a>
            </div>
          </Col>
        </Row>

        <hr className="divider divider--spacing-md" />

        {/* Management */}
        <Row className="row--grid">
          <Col xs={12} sm={2}>
            <strong className="text-uppercase">Management</strong>
          </Col>

          <Col xs={12} sm={4}>
            <strong>Robin Russell, Managing Director</strong>
            <p>
              <a className="text--color-default" href="tel:0738725501">(07)&nbsp;3872&nbsp;5501</a>
            </p>
            <HiddenEmail mailto="rr@robrus.com.au" />
          </Col>

          <Col xs={12} sm={3}>
            <strong>Kristine Russell, Practice Manager</strong>
            <p>
              <a className="text--color-default" href="tel:0738725505">(07) 3872 5505</a>
            </p>
            <HiddenEmail mailto="kr@robrus.com.au" />
          </Col>

          <Col xs={12} sm={3}>
            <strong>Ellen Russell, Director</strong>
            <p>
              <a className="text--color-default" href="tel:0738725502">(07) 3872 5502</a>
            </p>
            <HiddenEmail mailto="er@robrus.com.au" />
          </Col>

          {/* Second line */}
          <div className="row--col-group">
            <Col xs={12} sm={7} md={4} smOffset={2}>
              <strong>Darren Lloyd, Engineering Manager</strong>
              <p>
                <a className="text--color-default" href="tel:0738725504">(07) 3872 5504</a>
              </p>
              <HiddenEmail mailto="dl@robrus.com.au" />
            </Col>
          </div>
        </Row>
        <hr className="divider divider--spacing-md" />

        {/* Administration */}
        <Row className="row--grid">
          <Col xs={12} sm={2}>
            <strong className="text-uppercase">Administration</strong>
          </Col>

          <Col xs={12} sm={4}>
            <strong>Michelle Gray, Project Administrator</strong>
            <p>
              <a className="text--color-default" href="tel:0738725506">(07) 3872 5506</a>
            </p>
            <HiddenEmail mailto="mg@robrus.com.au" />
          </Col>

          <Col xs={12} sm={3}>
            <strong>Tania Deller, Council Liaison Officer</strong>
            <p>
              <a className="text--color-default" href="tel:0738725515">(07) 3872 5515</a>
            </p>
            <HiddenEmail mailto="td@robrus.com.au" />
          </Col>

          <Col xs={12} sm={3}>
            <strong>Jess Cowdroy, Project Administrator</strong>
            <p>
              <a className="text--color-default" href="tel:0738725506">(07) 3872 5506</a>
            </p>
            <HiddenEmail mailto="jc@robrus.com.au" />
          </Col>

          <div className="row--col-group">
          <Col xs={12} sm={4} smOffset={2}>
              <strong>Michael Cowdroy, Contract Administrator</strong>
              <p>
                <a className="text--color-default" href="tel:0738725510">(07) 3872 5510</a>
              </p>
              <HiddenEmail mailto="mc@robrus.com.au" />

            </Col>
          </div>
        </Row>

        <hr className="divider divider--spacing-md" />

        {/* Designers */}
        <Row className="row--grid">
          <Col xs={12} sm={2}>
            <strong className="text-uppercase">Designers</strong>
          </Col>

          {/* First line */}


          <Col xs={12} sm={4}>
            <strong>William Schardt, Electrical Designer</strong>
            <p>
              <a className="text--color-default" href="tel:0738725555">(07) 3872 5555</a>
            </p>
            <HiddenEmail mailto="ws@robrus.com.au" />
          </Col>

          <Col xs={12} sm={3}>
            <strong>Alex King, Electrical Designer</strong>
            <p>
              <a className="text--color-default" href="tel:0419731855">0419 731 855</a>
            </p>
            <HiddenEmail mailto="ak@robrus.com.au" />
          </Col>

          <Col xs={12} sm={3}>
            <strong>Justin Lucht, Electrical Designer</strong>
              <p>
                <a className="text--color-default" href="tel:0434121888">0434 121 888</a>
              </p>
              <HiddenEmail mailto="jl@robrus.com.au" />
            </Col>

          {/* Second line */}
          <div className="row--col-group">


            <Col xs={12} sm={4} smOffset={2}>
              <strong>Anthony Cumareng, Street Light Designer</strong>
              <p>
                <a className="text--color-default" href="tel:0738725508">(07) 3872 5508</a>
              </p>
              <HiddenEmail mailto="ac@robrus.com.au" />
            </Col>


            <Col xs={12} sm={3}>
            </Col>
          </div>
        </Row>

        <hr className="divider divider--spacing-md" />

        {/* Telecommunications Designers */}
        <Row className="row--grid">
          <Col xs={12} sm={2}>
            <strong className="text-uppercase visible-sm visible-md">Telecomm. Designers</strong>
            <strong className="text-uppercase visible-xs visible-lg">Telecommuncations Designers</strong>
          </Col>

          <Col xs={12} sm={4}>
            <strong>Shane Mills, Telecommunications Designer</strong>
            <p>
              <a className="text--color-default" href="tel:0738725555">(07) 3872 5555</a>
            </p>
            <HiddenEmail mailto="shane@robrus.com.au" />
          </Col>
        </Row>

        <hr className="divider divider--spacing-md" />

        {/* Construction managers */}
        <Row className="row--grid">
          <Col xs={12} sm={2}>
            <strong className="text-uppercase">ON-SITE CONSTRUCTION MANAGERS</strong>
          </Col>

          <Col xs={12} sm={4}>
            <strong>Shane Hyde, Construction Co-ordinator</strong>
            <p>
              <a className="text--color-default" href="tel:0419021772">0419 021 772</a>
            </p>
            <HiddenEmail mailto="shy@robrus.com.au" />
          </Col>

          <Col xs={12} sm={3}>

          </Col>
        </Row>
      </section>
    </div>

    {/* NOTE: callback form is temporary hidden */}
    {/* <CallbackForm /> */}

  </AppWrapper>
);

const carouselBannerLeft = (
  <h2 className="text--color-inverse banner-title">
    Contact Us
  </h2>
);

const carouselBannerRight = (
  <div className="info-block">
    <h3 className="text--color-inverse">Meet the team</h3>

    <hr className="divider divider--size-lg divider--spacing-sm divider--color-gray" />

    <p>
      In the field of subdivision electrical services, we are acknowledged industry
      leaders.
    </p>
    <Link to="/about-us#meettheteam" className="carousel-news-link text--style-medium">
      Meet the team +
    </Link>
  </div>
);

export default ContactUs;
