import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ImgDefaultBackground from "../../../assets/img-background-engineers_tmp.jpg";

import { Row, Col } from 'react-bootstrap';
import Flickity from 'flickity';


const defaultFlickityOptions = {
  cellSelector: '.carousel-cell',
  prevNextButtons: false,
  draggable: false,
};

const Carousel = (props) => {
  let flickity;
  
  useEffect(() => {
    defaultFlickityOptions.autoPlay = props.autoPlay;
    // TODO: refactor, because flickity is initializing each time when we change the page
    flickity = new Flickity('.carousel-robrus', defaultFlickityOptions);
  }, [])
  
  // componentDidMount() {
  //   defaultFlickityOptions.autoPlay = props.autoPlay;
  //   // TODO: refactor, because flickity is initializing each time when we change the page
  //   flickity = new Flickity('.carousel-robrus', defaultFlickityOptions);
  // }

  const getCarouselCells = (numOfCells) => {
    const cells = [];

    for (let index = 0; index < numOfCells; index += 1) {
      cells.push(
        <div className="carousel-cell" key={`carousel-cell-${index}-of-${numOfCells}`}>
          <div className="container cell-content">
            <Row className="row--align-vertically">
              {
                props.bannersLeft[index] && (
                  <Col
                    xs={12}
                    sm={props.bannerRight ? 8 : 12}
                    className="col--align-vertically col--vertically-middle banner banner-left"
                  >

                    {
                      props.bannersLeft[index]
                    }
                  </Col>
                )
              }
              {
                props.bannerRight && (
                  <Col
                    xs={12}
                    sm={4}
                    className="col--align-vertically banner banner-right"
                  >

                    {props.bannerRight}
                  </Col>
                )
              }
            </Row>
          </div>
          <img
            src={
              props.backgrounds[index] 
            }
            className="img-centered"
            alt="Carousel background"
          />
        </div>
      );
    }

    return cells;
  }

    return (
      <section className="carousel carousel-robrus carousel-transition-fade">
        {
          getCarouselCells(
            Math.max(props.backgrounds.length, props.bannersLeft.length)
          )
        }
        {/* <img src={ImgDefaultBackground} alt="dfsf"/> */}
      </section>
    );
}

Carousel.defaultProps = {
  backgrounds: [ImgDefaultBackground],
  bannersLeft: [],
  autoPlay: false,
};

Carousel.propTypes = {
  backgrounds: PropTypes.array,
  bannersLeft: PropTypes.array,
  bannerRight: PropTypes.node, // eslint-disable-line react/require-default-props
  autoPlay: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
};

export default Carousel;