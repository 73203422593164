import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import AppWrapper from '../AppWrapper';
import CardRobRus from '../../components/CardRobRus';

import IconServices1 from '../../assets/icon-telecommunication_tmp.png';
import IconServices2 from '../../assets/icon-electrical_tmp.png';
import IconServices3 from '../../assets/icon-street-lighting_tmp.png';

import ImgHomePageBackground1 from '../../assets/img-background-engineers_tmp.jpg';
import ImgHomePageBackground2 from '../../assets/img-background-electric-pole.jpg';

import PhotoRobinRussell from '../../assets/photo-robin-russell.png';
import PhotoKristineRussell from '../../assets/photo-kristine-russell.png';

import LogoMirvacQld from './images/partners/logo-mirvac-qld.png';
import LogoFrasersProperty from './images/partners/logo-frasers-property.png';
import LogoTraskLand from './images/partners/logo-trask-land.png';
import LogoPaskGroup from './images/partners/logo-paskgroup.png';

import LogoQMProperties from './images/partners/logo-qm-properties.png';
import LogoComiskeyCorp from './images/partners/logo-comiskeycorp.png';
import LogoFiteniHomes from './images/partners/logo-fitenihomes.png';
import LogoWintenPropertyGroup from './images/partners/logo-winten-property-group.png';

import NewsWidget from './news';

const HomePage = () => (
  <AppWrapper
    className="home-page"
    currentPath="/"

    carousel
    backgrounds={[ImgHomePageBackground1, ImgHomePageBackground2]}
    bannersLeft={[carouselBannerLeft, carouselBannerLeft2]}
    bannerRight={carouselBannerRight}
  >

    <Helmet>
      <title>Robin Russell &amp; Associates</title>
      <meta name="description" content="Subdivision construction services - Queensland" />
    </Helmet>

    <section className="services" role="appWrapper">
      <div className="container">
        <Row>
          <Col xs={12}>
            <h3 className="text-center text--style-light text--weight-max text--color-default">
              For more than 30 years, Robin Russell &amp; Associates has
              specialised in the <strong>design and construction management of
                subdivision electrical services</strong> for land developers.
              Because of our high turnover, we can achieve <strong>economies of
                scale</strong> that allow us to create added value - cheaper
              materials and <strong>lowest tender prices</strong>.
              Our services include:
            </h3>
          </Col>
        </Row>
        <Row className="row--grid services-description row--col-spaced--md">
          <Col sm={4} className="text-center animated zoomIn">
            <img src={IconServices2} className="service-icon" alt="Electrical" />
            <h4 className="description-title">Electrical</h4>
            <p>
              Developers must install electricity reticulation that meets the
              specifications of Energex/Ergon, the eventual owner of those
              assets. So the challenge is to know exactly what those
              specifications are.  They are very complex and there is no room
              for error. We have the experience to ensure that our designs are
              compliant, without the waste of costly overdesign.  Then we go
              further - we compile full materials lists, so that our clients
              can purchase all materials directly from Energex/Ergon, at a
              discounted rate. Contractors are selected by competitive
              tendering, to secure the lowest construction cost.
            </p>
            <Link to="/services#electrical">Read more +</Link>
          </Col>

          <Col sm={4} className="text-center animated zoomIn">
            <img src={IconServices3} className="service-icon" alt="Street Lighting" />
            <h4 className="description-title">Street Lighting</h4>
            <p>
              At present, most street lights are owned by Energex/Ergon, but
              councils are becoming increasingly interested in assuming
              ownership, as part of their &quot;smart city&quot; drive. Most
              councils now want LED street lights in new subdivisions and
              electricity authorities and suppliers are struggling to keep up
              with demand. We strongly support the move to LED street lighting
              and have taken several key initiatives to speed this up and
              facilitate the forthcoming change of ownership of street lights.
            </p>
            <Link to="/services#street-lighting">Read more +</Link>
          </Col>

          <Col sm={4} className="text-center animated zoomIn">
            <img src={IconServices1} className="service-icon" alt="Telecommunications" />
            <h4 className="description-title">Telecommunications</h4>
            <p>
              We organize the installation of conduits and pits for NBN or
              Telstra.  Close co-ordination with the electrical layout is
              required to ensure that pits are not installed in the path of
              future driveways.
            </p>
            <Link to="/services#telecommunications">Read more +</Link>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-center">
            <Link to="/services">
              <button className="btn btn-robrus btn--color-green">
                Learn more about our services
              </button>
            </Link>
          </Col>
        </Row>
      </div>
    </section>

    <CardRobRus className="nbn-telstra">
      <h3 className="text-center">
        NBN or Telstra? It&apos;s a jungle.
      </h3>
      <p className="description text-center" data-aos="fade-in">
        NBN will install fibre in large developments or if there is already
        fibre nearby. If not, Telstra will assume ownership of the new conduits
        and install copper wires as a temporary meaure, until the NBN brownfield
        rollout reaches the area.   Fast broadband is now a high priority for purchasers.
      </p>
      <p className="description text-center" data-aos="fade-in">
        If possible,they want fibre-to-the-premises. They can get this in many large
        subdivisions, where NBN installs its fibre; but whether to install
        fibre-to-the-premises or fibre-to-the-node is a commercial decision by NBN,
        as is the decision as to whether NBN will even service the new estate at all,
        or leave it to Telstra. For that matter, Telstra might even decide to provide
        only basic telephone services by wireless, if the brownfield roll-out of NBN
        fibre is not far away.  All of this is crucial for sales, but out of the hands of developers.
      </p>
      <p className="description text-center" data-aos="fade-in">
        We have close working relationships with both NBN and Telstra. That ensures that
        our clients not only achieve the most favourable outcomes, but that they are also
        kept fully informed on the way through the jungle.
      </p>
    </CardRobRus>

    <section className="our-team">
      <div className="container">
        <Row data-aos="fade-up">
          <Col xs={12}>
            <h3 className="text-center text--style-light text--weight-max text--color-default">
              <strong>Our Team.</strong> In the field of subdivision electrical
              services, we are acknowledged industry leaders.  We have achieved
              that position by <strong>engaging the best engineers, designers,
                and construction supervisors in this field</strong>. Most had
              extensive experience with the electricity supply authorities
              before joining us. They are a team of professionals.
            </h3>
          </Col>
        </Row>
        <Row className="team-member" data-aos="fade-down">
          <Col sm={4} mdOffset={2}>
            <img
              src={PhotoRobinRussell}
              className="photo img-responsive"
              alt="Robin Russell"
            />
          </Col>
          <Col sm={8} md={6}>
            <p className="position text-uppercase">
              <strong>Robin Russell &nbsp;|&nbsp;</strong> Managing director
            </p>
            <p>
              Robin is a chartered engineer, with the longest professional
              experience of anyone in the Queensland electricity supply industry.
              He had 23 years&apos; engineering and administrative experience
              with Brisbane City Council and the electricity supply industry.
              In 1985, he created the consulting practice that now produces more
              subdivision installations than any other consultant in Queensland
              - and possibly Australia.
            </p>
            <Link to="/about-us#robin-russell">Read more +</Link>
          </Col>
        </Row>
        <Row className="team-member" data-aos="fade-up">
          <Col sm={4} mdOffset={2}>
            <img
              src={PhotoKristineRussell}
              className="photo img-responsive"
              alt="Kristine Russell"
            />
          </Col>
          <Col sm={8} md={4}>
            <p className="position text-uppercase">
              <strong>Kristine Russell &nbsp;|&nbsp;</strong> Practice manager
            </p>
            <p>
              As Practice Manager, Kristine is responsible for day to day
              administration of the company&apos;s business practices. Her particular
              focus is on developing good client relationships. Kristine is also
              our telecommunications specialist.
            </p>
            <Link to="/about-us#kristine-russell">Read more +</Link>
          </Col>
        </Row>

        <Row className="text-center">
          <Link to="/about-us#meettheteam">
            <button className="btn btn-robrus btn--color-beige">
              Meet the team
            </button>
          </Link>
        </Row>
      </div>
    </section>

    <section className="our-clients">
      <div className="container">
        <Row>
          <Col xs={12}>
            <h3 className="text-center text--style-light text--weight-max text--color-default">
              <strong>Our Clients.</strong> Since 1985, we have designed and co-ordinated
              construction of more than <strong>9000 subdivisions</strong>,
              ranging from two-lot reconfigurations to 1000-lot master-planned
              communities. Our clients include most of the State&apos;s largest
              land developers. Our clients include:
            </h3>
          </Col>
        </Row>
        <Row data-aos="zoom-in">
          <Col xs={6} md={3}>
            <a href="https://www.traskland.com.au/" target="_blank">
              <img
                className="client-logo img-responsive"
                src={LogoTraskLand}
                alt="Trask Land logo"
              />
            </a>
          </Col>

          <Col xs={6} md={3}>
            <a href="http://www.mirvac.com/" target="_blank">
              <img
                className="client-logo img-responsive"
                src={LogoMirvacQld}
                alt="Mirvac Qld logo"
              />
            </a>
          </Col>

          <Col xs={6} md={3}>
            <a href="https://www.frasersproperty.com.au/" target="_blank">
              <img
                className="client-logo img-responsive"
                src={LogoFrasersProperty}
                alt="Frasers Property logo"
              />
            </a>
          </Col>

          <Col xs={6} md={3}>
            <a href="http://www.nevpask.com.au/" target="_blank">
              <img
                className="client-logo img-responsive"
                src={LogoPaskGroup}
                alt="PaskGroup logo"
              />
            </a>
          </Col>
        </Row>
        <Row data-aos="zoom-in" data-aos-delay="100">
          <Col xs={6} md={3}>
            <a href="http://www.qm.com.au/" target="_blank">
              <img
                className="client-logo img-responsive"
                src={LogoQMProperties}
                alt="QM Properties logo"
              />
            </a>
          </Col>

          <Col xs={6} md={3}>
            <a href="http://www.comiskeycorp.com.au/main.html" target="_blank">
              <img
                className="client-logo img-responsive"
                src={LogoComiskeyCorp}
                alt="Comiskey Corp Property Developers logo"
              />
            </a>
          </Col>

          <Col xs={6} md={3}>
            <a href="http://www.fitenihomes.com.au/" target="_blank">
              <img
                className="client-logo img-responsive"
                src={LogoFiteniHomes}
                alt="Fiteni Homes logo"
              />
            </a>
          </Col>

          <Col xs={6} md={3}>
            <a href="http://www.winten.com.au/" target="_blank">
              <img
                className="client-logo img-responsive"
                src={LogoWintenPropertyGroup}
                alt="Winten Property Group logo"
              />
            </a>
          </Col>
        </Row>
      </div>
    </section>

    <section className="callback-request text-center">
      <div className="container">
        <Row>
          <Col xs={12}>
            <h3>
              For more information, please contact Robin Russell &amp; Associates.
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Link to="/contact-us">
              <button className="btn btn-robrus btn--color-cascade animated pulse infinite">
                Contact us
              </button>
            </Link>
          </Col>
        </Row>
      </div>
    </section>
  </AppWrapper>
);

const carouselBannerLeft = (
  <div>
    <h2 className="text--color-inverse banner-title">
      Consulting Electrical Engineers, delivering
      <span className="text--color-feldgrau"> quality subdivision electrical services</span>,
      with full professional support.
    </h2>
    <Link to="/services">
      <button className="btn btn-robrus">Learn about our services</button>
    </Link>
  </div>
);

const carouselBannerLeft2 = (
  <div>
    <h2 className="text--color-inverse banner-title">
      In the field of subdivision electrical services, we are acknowledged
      <span className="text--color-feldgrau"> industry leaders</span>.
    </h2>
    <Link to="/about-us#meettheteam">
      <button className="btn btn-robrus">Meet the team</button>
    </Link>
  </div>
);

const carouselBannerRight = (
  <div className="info-block info-home">
    <h3 className="text--color-inverse">Latest News</h3>
    <hr className="divider divider--size-lg divider--spacing-sm divider--color-gray" />
    <NewsWidget />
    <Link to="/news" className="carousel-news-Link text--style-medium">
      View all news articles +
    </Link>
  </div>
);

export default HomePage;
