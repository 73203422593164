import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import kebabCase from "lodash/kebabCase";
import AppWrapper from "../AppWrapper";
import ImgNewsBackground from "../../assets/img-background-light_bulb.jpg";

import CurrentArticle from "./sub-pages/current-article";
import GroupedNews from "./sub-pages/grouped-news";

import SectionNews from "./sub-components/section-news";
import LatestNews from "./sub-components/latest-news";
import {
  getFetchLastData,
  getFetchPostsData,
  getFetchPrewData,
} from "../../api/getData";
import "./styles.scss";

const News = () => {
  const [data, setData] = useState({
    posts: {},
    prew: [],
    last: [],
  });

  const params = useParams();
  const newsYear = params.year;
  const articleTitle = params.title;

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const fetchPostsData = await getFetchPostsData();
    const fetchLastData = await getFetchLastData();
    const fetchPrewData = await getFetchPrewData();
    setData({
      ...data,
      posts: fetchPostsData,
      prew: fetchPrewData.items,
      last: fetchLastData.items,
    });
  };  

  return (
    <AppWrapper
      className="news-page"
      currentPath="/news"
      carousel
      backgrounds={[ImgNewsBackground]}
      bannersLeft={[carouselBannerLeft]}
      bannerRight={carouselBannerRight}
    >
      <Helmet>
        <title>Robin Russell &amp; Associates | News</title>
        <meta
          name="description"
          content="Subdivision construction services - Queensland"
        />
      </Helmet>

      <div className="container" role="appWrapper">
        {newsYear ? (
          renderNewsSubpage(newsYear, articleTitle, data.posts, data.last)
        ) : (
          <div>
            {data.last && data.last.length && (
              <LatestNews title="Latest news" articles={data.last} />
            )}
            <SectionNews title="2016" articles={data.prew} />
          </div>
        )}
      </div>
    </AppWrapper>
  );
};

const carouselBannerLeft = (
  <h2 className="text--color-inverse banner-title">News</h2>
);

const carouselBannerRight = (
  <div className="info-block">
    <h3 className="text--color-inverse">Meet the team</h3>
    <hr className="divider divider--size-lg divider--spacing-sm divider--color-gray" />
    <p>
      In the field of subdivision electrical services, we are acknowledged
      industry leaders.
    </p>
    <Link
      to="/about-us#meettheteam"
      className="carousel-news-link text--style-medium"
    >
      meet the team +
    </Link>
  </div>
);

// Render helpers
const renderNewsSubpage = (newsYear, articleTitle, posts, lastPosts) => {
  const currentArticle =
    articleTitle && posts.items && getArticleByTitle(posts.items, articleTitle);
  return articleTitle && posts.items ? (
    <CurrentArticle
      {...currentArticle.fields}
      path={articleTitle}
      latestNews={lastPosts
        .filter((post) => post.sys.id !== currentArticle.sys.id)
        .slice(0, 3)}
    />
  ) : (
    lastPosts && lastPosts.length && (
      <GroupedNews title={newsYear} news={lastPosts} />
    )
  );
};

// Helpers
const getArticleByTitle = (news, title) => {
  let currentArticle = null;

  news.forEach((article) => {
    if (kebabCase(article.fields.title) === title) currentArticle = article;
  });

  return currentArticle;
};


// const mapStateToProps = (state) => ({
//   posts: state.get("posts").all,
//   prew: state.get("posts").prew,
//   last: state.get("posts").last,
// });

// export function mapDispatchToProps(dispatch) {
//   return bindActionCreators({ fetchPosts, fetchPrew, fetchLast }, dispatch);
// }

// export default connect(mapStateToProps, mapDispatchToProps)(News);
export default News;
