/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, Children } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Flickity from 'flickity';
import kebabCase from 'lodash/kebabCase';

import ImgDefaultBackground from './images/vertical-carousel-default-bg_tmp.jpg';

import './styles.scss';

const defaultFlickityOptions = {
  cellSelector: '.carousel-cell',
  prevNextButtons: false,
  pageDots: false,
  draggable: false,
};

const VerticalCarousel = (props) => {
  const [selectedCell, setSelectedCell] = useState(0);

  let flickity;

  useEffect(() => {
    flickity = new Flickity('.carousel-content', defaultFlickityOptions);
  }, [selectedCell]);

  const getNavigationItems = () => {
    const navigationItems = Children.map(props.children, (child, index) => (
      <li
        key={`vc-nav-item-${index}-${kebabCase(child.props.navTitle)}`}
        className={
          selectedCell === index
            ? 'navigation-item navigation-item--selected'
            : 'navigation-item'
        }
      >

        <button onClick={() => handleCellChange('position', index)} className="nav-btn">
          <i className="icon--bullet"></i>
          <span className="title">{child.props.navTitle}</span>
        </button>
      </li>
    ));

    // Add prev and next buttons
    if (navigationItems.length > 1) {
      navigationItems.unshift(
        <li key="vc-nav-item-minus-1-prev-button" className="navigation-item">
          <button onClick={() => handleCellChange('prev')} className="nav-btn">
            <i className="fa fa-angle-up fa-2x" aria-hidden="true"></i>
          </button>
        </li>
      );

      navigationItems.push(
        <li
          key={`vc-nav-item-${navigationItems.length + 1}-next-button`}
          className="navigation-item"
        >

          <button onClick={() => handleCellChange('next')} className="nav-btn">
            <i className="fa fa-angle-down fa-2x" aria-hidden="true"></i>
          </button>
        </li>
      );
    }

    return navigationItems;
  }

  const handleCellChange = (direction, position) => {

    switch (direction) {
      case 'prev':
        flickity.previous();
        break;
      case 'position':
        flickity.selectCell(position);
        break;
      case 'next':
        flickity.next();
        break;
      // no default
    }

    setSelectedCell(flickity.selectedIndex);
  }

    return (
      <section className="vertical-carousel">
        <div className="container">
          <Row className="row--spacing-off row--align-vertically row--reorder--xs">
            <Col xs={12} sm={8} className="col--align-vertically">
              <div className="carousel-content">
                {Children.toArray(props.children)}
              </div>
            </Col>
            <Col
              xs={12}
              sm={4}
              className="col--align-vertically col--vertically-middle carousel-navigation"
            >

              <ul>
                {getNavigationItems()}
              </ul>
              <img
                src={ImgDefaultBackground}
                className="navigation-background"
                alt="Vertical carousel"
              />
            </Col>
          </Row>
        </div>
      </section>
    );
}

VerticalCarousel.Cell = ({ children }) => (
  <div className="carousel-cell">
    {children}
  </div>
);

VerticalCarousel.propTypes = {
  children: PropTypes.node.isRequired,
};

// NOTE: we are using Cell title and background for custom carusel navigation
VerticalCarousel.Cell.propTypes = {
  // eslint-disable-next-line react/require-default-props, react/no-unused-prop-types
  navTitle: PropTypes.string,
  // eslint-disable-next-line react/require-default-props, react/no-unused-prop-types
  navBackground: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default VerticalCarousel;