import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import './styles.scss';

const CardRobRus = ({ children, className }) => (
  <div className="card-robrus">
    <div className="container">
      <Row>
        <Col xs={12}>
          <div className={`card-content${className ? ` ${className}` : ''}`}>
            {
              Children.toArray(children)
            }
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

CardRobRus.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string, // eslint-disable-line react/require-default-props
};

export default CardRobRus;
