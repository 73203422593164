import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import AppWrapper from '../AppWrapper';
import CardRobRus from '../../components/CardRobRus';

import PhotoRobinRussell from '../../assets/photo-robin-russell.png';
import PhotoKristineRussell from '../../assets/photo-kristine-russell.png';
import PhotoDarrenlloyd from '../../assets/photo-darren-lloyd.png';
import PhotoEllenRussell from '../../assets/photo-ellen-russell.png';
import PhotoTaniaDeller from '../../assets/photo-tania-deller.png';
import PhotoJessCurameng from '../../assets/photo-jess-curameng.png';
import PhotoMichaelCowdroy from '../../assets/photo-michael-cowdroy.png';
import PhotoWilliamSchardt from '../../assets/photo-william-schardt.png';
import PhotoJustinLucht from '../../assets/photo-justin-lucht.png';
import PhotoShaneMills from '../../assets/photo-shane-mills.png';
import PhotoShaneHyde from '../../assets/photo-shane-hyde.png';
import PhotoAnthonyCurameng from '../../assets/photo-anthony-curameng.png';
import PhotoMichelleGray from '../../assets/photo-michelle-gray.png';
import PhotoAlexKing from '../../assets/photo-alex-king.png';
import PhotoBrettHyland from '../../assets/photo-brett-hyland.png';

import LogoBSI from './images/BSI-LogoISO9001.png';
import './styles.scss'

const AboutUs = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
  <AppWrapper
    className="about-us-page"
    currentPath="/about-us"

    carousel
    bannersLeft={[carouselBannerLeft]}

  >

    <Helmet>
      <title>Robin Russell &amp; Associates | About Us</title>
      <meta name="description" content="Subdivision construction services - Queensland" />
    </Helmet>

    <div className="container" role="appWrapper">
      <section className="about-info">
        <Row>
          <Col xs={12}>
            <h3 className="text--style-light text--weight-max text--color-default">
              Robin Russell &amp; Associates Pty Ltd is a Queensland-based
              consulting engineering company specialising in <strong>electrical,
                street lighting and telecoms installations </strong> for new
              subdivisions.
            </h3>
            <p>
              We handle more subdivisions than any other consultant in
              Queensland, and possibly in Australia. We studiously avoid
              potential conflicts of interest by having no contractual
              committments to Energex or Ergon Energy. Our service extends
              beyond design, to obtaining the cheapest materials and providing a
              full contract superintendent service for construction. In this
              way, we believe we can secure the lowest cost design +
              construction for our clients, without delays.
            </p>
            <p>
              <strong>Experience.</strong> Our experience is unchallenged.  In
              the past 30 years, we have designed and co-ordinated electrical
              installation in more than 9000 Queensland subdivisions. These
              range from 2-lot subdivisions to 1000-lot master-planned
              communities.
            </p>
            <p>
              <strong>Working with Energex &amp; Ergon.</strong> Under the present
              management of Energy Queensland, Energex and Ergon may be
              Queensland&apos;s highest performing government corporations. They are
              very customer-focused and their staff are extremely competent.
              In our experience, if you know their processes and who to talk to,
              it is always possible to get a fair and reasonable outcome. Most
              of RRA&apos;s senior staff are former Energex/Ergon employees. They
              know how the system works. (In some cases, they developed it.)
              So they are able to talk to the right person to resolve knotty
              problems speedily and without confrontation. This assures clients
              of the best possible outcome.
            </p>
          </Col>
        </Row>
      </section>
    </div>

    <CardRobRus className="certifications-and-accreditations text-center">
      <h3>
        Certifications &amp; Accreditations.
      </h3>
      <p className="description" data-aos="fade-in">
        Our Quality Management System is certified by <strong>BSI to ISO
          9001:2017</strong>. The Scope of Certification is specific to
        subdivision development. Design and supervision of the installation of
        electricity reticulation, street lighting, telecommunications conduits
        and pits. We hold <strong>Energex and Ergon Energy Consultant
          &quot;A&quot; ratings</strong> - so our clients attract Energex&apos;s
        and Ergon&apos;s lowest checking fees.
      </p>
      <img src={LogoBSI} alt="BSI logo" />
      <p>FS 604839</p>
    </CardRobRus>

    <span className="anchor" id="meettheteam"></span>
    <section className="our-team">
      <div className="container">
        <Row data-aos="fade-down">
          <Col xs={12}>
            <h3 className="text-center text--style-light text--weight-max text--color-default">
              <strong>Our Team.</strong> In the field of subdivision electrical
              services, we are acknowledged industry leaders.  We have achieved
              that position by <strong>engaging the best engineers, designers,
                and construction supervisors in this field</strong>. Most had
              extensive experience with the electricity supply authorities
              before joining us. They are a team of professionals.
            </h3>
          </Col>
        </Row>

        <span className="anchor" id="robin-russell"></span>
        <Row className="team-member" data-aos="fade-up">
          <Col sm={4}>
            <img
              src={PhotoRobinRussell}
              className="photo img-responsive"
              alt="Robin Russell"
            />
          </Col>
          <Col sm={8}>
            <p className="text-uppercase">
              <strong>Robin Russell &nbsp;|&nbsp;</strong> Managing director
            </p>
            <p>
              Robin is a chartered engineer, with the longest professional
              experience of anyone in
              the Queensland electricity industry. <strong>BE, BCom, FIEAust,
                CPEng, NER, RPEQ.</strong>
            </p>
            <p>
              He had 23 years&apos; engineering and administrative experience
              with Brisbane City Council and the electricity supply industry. In
              1985, when the design and construction of electricity reticulation
              and street lighting became contestable in south east Queensland,
              he accepted the challenge and created the consulting practice for
              this purpose. He progressively recruited a number of experienced
              ex-Energex design staff, with the aim of achieving the
              industry&apos;s highest subdivision design standards. The company
              and its scope of work have grown over the past 30 years.
              Telecommunications infrastructure is now part of its core
              business. Geographically, its reach has spread into Ergon
              Energy&apos;s service area of regional Queensland. It remains the
              largest consulting practice in Queensland focused on the design
              and construction management of electricity reticulation, street
              lighting and telecommunications infrastructure. For several years,
              Robin also operated an electrical contracting company which
              performed a wide range of construction and maintenance work for
              SEQEB. This provided invaluable insight into the problems and
              challenges faced by electrical contractors. By knowing how to work
              with contractors rather than against them, the company has
              developed a synergy with contractors, which serves clients&apos;
              ultimate advantage.
            </p>
            <p>
              Robin&apos;s accreditations and affiliations include: Fellow,
              Institution of Engineers Australia, Chartered Professional
              Engineer, National Engineers Registration, Registered Professional
              Engineer Queensland (No. 1546), Member UDIA (Qld).
            </p>
            <Link to="/contact-us">Contact +</Link>
          </Col>
        </Row>

        <Row className="row--col-spaced--md text-center" data-aos="fade-down">
          <span className="anchor" id="kristine-russell"></span>
          <Col sm={4}>
            <img
              src={PhotoKristineRussell}
              className="photo photo-sm img-responsive"
              alt="Kristine Russell"
            />
            <p className="position text-uppercase">
              <strong>Kristine Russell</strong> | Practice manager
            </p>
            <p>
              As Practice Manager, Kristine is responsible for day to day
              administration of the company&apos;s business practices. Before
              joining our team, Kristine&apos;s experience was in managing the
              supply of materials for commercial architectural fitouts. Her
              particular focus was on developing good client relationships and
              this is now her major contribution to the engineering practice.
              Kristine also has responsibility for managing telecommunications
              infrastructure development. This is a rapidly changing field.
              Kristine keeps aware of current developments by maintaining close
              contact with key personnel in NBN and Telstra.
            </p>
            <Link to="/contact-us">Contact +</Link>
          </Col>

          <Col sm={4}>
            <img
              src={PhotoDarrenlloyd}
              className="photo photo-sm img-responsive"
              alt="Darren Lloyd"
            />
            <p className="position text-uppercase">
              <strong>Darren Lloyd</strong> | Engineering Manager
            </p>
            <p>
              Darren has in excess of 40 years utilities industry experience across distribution and transmission design and planning, network standards, network operations and construction including the development of national, state and organisational policies, technical specifications and standards. Darren joined us in 2017, having served previously as Energex&apos;s Group Manager Design, responsible for design of Energex&apos;s transmission and distribution networks. Darren&apos;s main responsibilities are leading and enabling the design and construction teams and developing policies and procedures relating to engineering standards and road lighting. He also provides close liaison with Energy Queensland and local authorities. Darren&apos;s objective is to maintain our position as the leading subdivision electrical consultant in Queensland.<strong>BE(Hons), MEngSc, RPEQ, RPEng</strong></p>
            <Link to="/contact-us">Contact +</Link>
          </Col>

          <Col sm={4}>
            <img
              src={PhotoEllenRussell}
              className="photo photo-sm img-responsive"
              alt="Ellen Russell"
            />
            <p className="position text-uppercase">
              <strong>Ellen Russell</strong> | Director
            </p>
            <p>
              One of the company&apos;s directors, Ellen has responsibility for
              the company&apos;s accounts.
            </p>
            <Link to="/contact-us">Contact +</Link>
          </Col>
        </Row>

        <Row className="row--col-spaced--md text-center" data-aos="fade-up">


          <Col sm={4}>
            <img
              src={PhotoTaniaDeller}
              className="photo photo-sm img-responsive"
              alt="Tania Deller"
            />
            <p className="position text-uppercase">
              <strong>Tania Deller</strong> | Council Liaison Officer
            </p>
            <p>
              Tania is responsible for gaining design approvals from councils
              and Department of Transport&amp; Main Roads - perhaps our most
              challenging assignment.
            </p>
            <Link to="/contact-us">Contact +</Link>
          </Col>

          <Col sm={4}>
            <img
              src={PhotoJessCurameng}
              className="photo photo-sm img-responsive"
              alt="Jess Curameng"
            />
            <p className="position text-uppercase">
              <strong>Jess Curameng</strong> | Project Administrator
            </p>
            <p>
              Jess is one of two Project Administrators, whose job it is to
              distribute designs to all affected parties and to ensure that they
              know what we are asking them to do. Jess manages jobs in Ergon&apos;s
              area - from the initial application to the Ergon Agreement.
            </p>
            <Link to="/contact-us">Contact +</Link>
          </Col>

          <Col sm={4}>
            <img
              src={PhotoMichelleGray}
              className="photo photo-sm img-responsive"
              alt="Michelle Gray"
            />
            <p className="position text-uppercase">
              <strong>Michelle Gray</strong> | Project Administrator
            </p>
            <p>
              Michelle was employed by Energex from 2003 to 2018 in their Subdivision Support group. Her responsibilities included the processing of developer&apos;s Agreements. For 18 months she was seconded to Energex&apos;s Purchasing Department, from whom we purchase all materials. With this experience, Michelle is excellently placed to handle all of our subdivision-related matters with Energex. She knows the people and their procedures. If there is a problem, she knows exactly how to handle it.
            </p>
            <Link to="/contact-us">Contact +</Link>
          </Col>
        </Row>

        <Row className="row--col-spaced--md text-center" data-aos="fade-down">

        <Col sm={4}>
            <img
              src={PhotoMichaelCowdroy}
              className="photo photo-sm img-responsive"
              alt="Michael Cowdroy"
            />
            <p className="position text-uppercase">
              <strong>Michael Cowdroy</strong> | Contract Administrator
            </p>
            <p>
            Michael is our Contract Administrator – responsible for establishing and administering our clients&apos; contracts with electrical contractors, in accordance with AS 4000:1997.  This includes:
<ul style={{textAlign: "left"}}><li>calling tenders,</li>
<li>sending recommendations to clients,</li>
<li>engaging the client-approved contractor,</li>
<li>establishing contract start dates,</li>
<li>contractor&apos;s progress claims, </li>
<li>contract variations,</li>
<li>extensions of time.</li>
</ul>
Michael&apos;s extensive commercial background with a large multinational manufacturer has equipped him well for this role.
            </p>
            <Link to="/contact-us">Contact +</Link>
          </Col>

          <Col sm={4}>
            <img
              src={PhotoAnthonyCurameng}
              className="photo photo-sm img-responsive"
              alt="Anthony Curameng"
            />
            <p className="position text-uppercase">
              <strong>Anthony Curameng</strong> | Street Light Designer
            </p>
            <p>
              Anthony&apos;s background is in computer system administration and network security, for which we originally engaged him. However this was a perfect intro to road lighting design, because it is computer-based. So Anthony is now our road lighting specialist.  Our electrical designers refer the more complex illumination studies to him for his expert attention.
            </p>
            <Link to="/contact-us">Contact +</Link>
          </Col>

          <Col sm={4}>
            <img
              src={PhotoAlexKing}
              className="photo photo-sm img-responsive"
              alt="Alex King"
            />
            <p className="position text-uppercase">
              <strong>Alex King</strong> | Electrical Designer
            </p>
            <p>
              Alex has worked in the electricity industry for 45 years. He is one of Queensland&apos;s most experienced electricity reticulation and street lighting designers.</p>
            <p>
              Alex has had extensive experience in the planning and design of electricity distribution and road lighting. He is a road lighting specialist, with a Certificate in Illumination Engineering. Within Energex and its predecessors, Alex was responsible variously for public lighting auditing, works programming, quality management, technical investigations, contract management and technical standards. And by the way - never challenge Alex to a bike race. He&apos;ll win. <strong>Assoc.Dip.ElecEng, Cert.Illum.Eng, Cert.Elec.Dist - Mains Design, TIES, AMIEAust</strong>
            </p>
            <Link to="/contact-us">Contact +</Link>
          </Col>

        </Row>

        <Row className="row--col-spaced--md text-center" data-aos="fade-up">
          <Col sm={4}>
            <img
              src={PhotoWilliamSchardt}
              className="photo photo-sm img-responsive"
              alt="William Schardt"
            />
            <p className="position text-uppercase">
              <strong>William Schardt</strong> | Electrical Designer
            </p>
            <p>
              William began his working life as an electrical tradesman in 1987.
              He gained an Associate Diploma in Electrical Engineering in 1990.
              William spent the first spent 21 years of his working life with
              SEQEB/Energex, where he gaining detailed experience of the
              industry. He started in the field as an electrician/linesman, but
              soon became a design officer, specialising in design of the
              electricity network and street lighting. For several years, before
              leaving Energex, William served as Subdivision Coordinator,
              responsible for managing Energex&apos;s subdivision development
              process. William is our main Ergon designer. Not only
              does he have a thorough technical knowledge in this field, but he
              also knows Energex, Ergon and Council processes very well. Often
              this is the key to a successful job.
            </p>
            <Link to="/contact-us">Contact +</Link>
          </Col>

          <Col sm={4}>
            <img
              src={PhotoJustinLucht}
              className="photo photo-sm img-responsive"
              alt="Justin Lucht"
            />
            <p className="position text-uppercase">
              <strong>Justin Lucht</strong> | Electrical Designer
            </p>
            <p>
              Justin holds a Certificate 3 in Engineering.<br /><br />
              He has had 15 years&apos; experience in designing and drafting subdivision electricity reticulation and street lighting. Justin&apos;s speciality is the more complex design of the overhead network.  This is becoming a rare skill these days, as almost all new electricity reticulation in the distribution system is underground.

            </p>
            <Link to="/contact-us">Contact +</Link>
          </Col>
          <Col sm={4}>
          <img
              src={PhotoShaneMills}
              className="photo photo-sm img-responsive"
              alt="Shane Mills"
            />
            <p className="position text-uppercase">
              <strong>Shane Mills</strong> | Telecommunications Designer
            </p>
            <p>
              Shane qualified as an Architectural Draftsman in 1991. Originally
              working in the residential sector Shane was involved in new home
              design, large home extensions and small developments. He joined
              RRA in 2010. Shane produces our NBN and Telstra designs. He must
              work closely with both organizations to ensure our designs meet
              their different criteria. In the case of NBN designs, he actually
              produces two drawings for every job - one drawing for NBN&apos;s
              use and one which the construction contractor can read and
              understand.
            </p>
            <Link to="/contact-us">Contact +</Link>
          </Col>
        </Row>

        <Row className="row--col-spaced--md text-center" data-aos="fade-up">
          <Col sm={4}>
          <img
              src={PhotoShaneHyde}
              className="photo photo-sm img-responsive"
              alt="Shane Hyde"
            />
            <p className="position text-uppercase">
              <strong>Shane Hyde</strong> | Construction Co-ordinator
            </p>
            <p>
              Shane started in the industry as an electrical tradesman in 1977.
              In 1986 he was appointed to a supervisory position with one of the
              largest electricity reticulation contractors. Initially the
              company worked as contractor to SEQEB, but gradually became one of
              the most prolific subdivision constructors. Shane joined RRA in
              2013, to serve as Construction Manager in Brisbane and
              Ergon&apos;s regional supply. Shane&apos;s knowledge of
              subdivision electricity reticulation construction is beyond
              compare. His unrivalled experience as a contractor specialising in
              this work means that he talks the same language as contractors.
              Potential problems are resolved quickly, and without fuss.
            </p>
            <Link to="/contact-us">Contact +</Link>
          </Col>



        </Row>


      </div>
    </section>
  </AppWrapper>
)};

const carouselBannerLeft = (
  <h2 className="text--color-inverse banner-title">
    About Us
  </h2>
);

export default AboutUs;
