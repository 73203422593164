import React, { Component } from 'react';
import { Collapse, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './styles.scss';

export default class CollapseElement extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      active: this.props.defaultActive,
      open: this.props.defaultActive,
    };
  }
  componentDidMount() {
    const node = this.el;
    if (this.props.defaultActive) {
      node.scrollIntoView();
      window.scrollBy(0, -80);
    }
  }
  render() {
    const { title, children, anchorLink } = this.props;
    const elementClass = `collapse-element ${this.state.active}`;
    return (
      <section ref={(el) => { this.el = el; }} className={elementClass}>
        <Button
          onClick={() => this.setState({ open: !this.state.open, active: !this.state.active })}
          bsStyle="link"
        >
          {title}
          <a name={anchorLink} className="faq-anchor" title={'Link to this question'} href={`#${anchorLink}`}>#</a>
          <span className="down"></span>
        </Button>
        <Collapse in={this.state.open}>
          <div>
            {children}
          </div>
        </Collapse>
      </section>
    );
  }
}

CollapseElement.defaultProps = {
  defaultActive: false,
};


