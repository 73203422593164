import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import Article from '../../../components/Article';

const SectionNews = ({ articles, title }) => (
  <section className="news--section" data-aos="zoom-in-up">
    <h3 className="text--style-medium text--color-default">{title}</h3>
    <hr className="divider divider--spacing-sm" />

    <Row>
      {
        articles.length
          ? articles.map((article) => (
            <Col xs={12} sm={4} key={`article-section-${article.fields.slug}`}>
              <Article {...article.fields} minimal />
            </Col>
          ))
          : (<Col xs={12}><h3 className="text-center">There is no news for that year.</h3></Col>)
      }
    </Row>
  </section>
);

SectionNews.defaultProps = {
  articles: [],
  title: '',
};

SectionNews.propTypes = {
  articles: PropTypes.array,
  title: PropTypes.string,
};

export default SectionNews;
