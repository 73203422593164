import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import Article from '../../../components/Article';
import SectionNews from './../sub-components/section-news';

const CurrentArticle = (props) => (
  <section className="news--current-article">
    <Row>
      <Col xs={12}>
        {
          props.title.length
            ? (<Article title={props.title} date={props.date} body={props.body} />)
            : (<h3 className="text-center">There is no such an article.</h3>)
        }
      </Col>
    </Row>

    <SectionNews articles={props.latestNews} title="Latest news" />
  </section>
);

CurrentArticle.defaultProps = {
  title: '',
  date: '',
  latestNews: [],
};

CurrentArticle.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string, // eslint-disable-line react/require-default-props, react/no-unused-prop-types
  date: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  latestNews: PropTypes.array,
};

export default CurrentArticle;
