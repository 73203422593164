import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import Article from '../../../components/Article';

const GroupedNews = ({ title, news }) => (
  <section className="news--grouped">
    <h4>{title}</h4>
    <hr className="divider divider--spacing-md" />

    <Row>
      {
        news.length
          ? news.map((article) => (
            <Col xs={4} key={`article-grouped-${article.fields.slug}`}>
              <Article {...article.fields} minimal />
            </Col>
          ))
          : (<Col xs={12}><h3 className="text-center">There is no news for that year.</h3></Col>)
      }
    </Row>
  </section>
);

GroupedNews.defaultProps = {
  title: '',
  news: [],
};

GroupedNews.propTypes = {
  title: PropTypes.string, // eslint-disable-line react/require-default-props
  news: PropTypes.array,
};

export default GroupedNews;
