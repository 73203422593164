import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import AppWrapper from "../AppWrapper";
import FaqItems from "./items";
import { getFaqData } from "../../api/getData";
import "./styles.scss";

const FaqPage = () => {
  const [newCurrentData, setNewCurrentData] = useState({});

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const result = await getFaqData();
    const temp = mapFaq(result.includes.Entry, result.items);
    setNewCurrentData(temp);
  };

  const mapFaq = (items, categories) => {
    
    if (items.length && categories.length) {
      const faqItems = [];
      items.forEach((item) => {
        faqItems[item.sys.id] = item.fields;
      });
      return {
        categories: categories
          .sort((a, b) => {
            if (a.fields.order > b.fields.order) {
              return 1;
            }
            if (a.fields.order < b.fields.order) {
              return -1;
            }
            return 0;
          })
          .map((category) => ({
            id: category.sys.id,
            categoryName: category.fields.title,
            items: category.fields.questions
              ? category.fields.questions.map((q) => {
                  const { title, content } = faqItems[q.sys.id];
                  const anchorLink = `${category.fields.title
                    .toLowerCase()
                    .replace(/ /g, "-")}-${title
                    .toLowerCase()
                    .replace(/ /g, "-")}`;
                  return {
                    id: q.sys.id,
                    defaultActive: false,
                    anchorLink,
                    title,
                    content,
                  };
                })
              : [],
          })),
      };
    }
    return null;
  };

  return (
    <AppWrapper
      className="faq-page"
      currentPath="/faq"
      carousel
      bannersLeft={[carouselBannerLeftOne]}
      bannerRight={carouselBannerRight}
    >
      <div role="appWrapper"/>
      <Helmet>
        <title >Robin Russell &amp; Associates | FAQ</title>
        <meta
          name="description"
          content="Subdivision construction services - Queensland"
        />
      </Helmet>

      {newCurrentData ? <FaqItems currentData={newCurrentData} /> : <div />}
    </AppWrapper>
  );
};

const carouselBannerLeftOne = (
  <h2 className="text--color-inverse banner-title">
    FAQ&apos;s & Services Information
  </h2>
);

const carouselBannerRight = (
  <div className="info-block">
    <h3 className="text--color-inverse">Meet the team</h3>

    <hr className="divider divider--size-lg divider--spacing-sm divider--color-gray" />

    <p>
      In the field of subdivision electrical services, we are acknowledged
      industry leaders.
    </p>
    <Link
      to="/about-us#meettheteam"
      className="carousel-news-link text--style-medium"
    >
      Meet the team +
    </Link>
  </div>
);

export default FaqPage;
