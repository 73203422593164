import React, { useState } from "react";
import PropTypes from "prop-types";

import "./styles.scss";

const HiddenEmail = (props) => {
  const [isEmailHidden, setIsEmailHidden] = useState(true);

  return (
    <span className="hidden-email">
      {isEmailHidden ? (
        <button
          onClick={() =>
            setIsEmailHidden(!isEmailHidden)
          }
          className="hidden-email__btn"
        >
          Show Email +
        </button>
      ) : (
        <a href={`mailto: ${props.mailto}`} className="hidden-email__link">
          {props.mailto}
        </a>
      )}
    </span>
  );
};

HiddenEmail.propTypes = {
  mailto: PropTypes.string.isRequired,
};

export default HiddenEmail;