import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation} from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import AppWrapper from '../AppWrapper';
import VerticalCarousel from '../../components/VerticalCarousel';

import IconQuicklinkTelecommunications from '../../assets/icon-telecommunication_tmp.png';
import IconQuicklinkElectrical from '../../assets/icon-electrical_tmp.png';
import IconQuicklinkStreetLighting from '../../assets/icon-street-lighting_tmp.png';
import IconQuicklinkGas from '../../assets/icon-gas_tmp.png';
import IconQuicklinkOtherServices from '../../assets/icon-monitor_tmp.png';

import IconSectionTelecommunications from './images/section-telecommunications-icon_tmp.png';
import IconSectionElectrical from './images/section-electrical-icon_tmp.png';
import IconSectionStreetLighting from './images/section-street-lighting-icon_tmp.png';

import './styles.scss';

const Services = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
  <AppWrapper
    className="services-page"
    currentPath="service"

    carousel
    bannersLeft={[carouselBannerLeft]}
    bannerRight={carouselBannerRight}
  >

    <Helmet>
      <title>Subdivision NBN Connections and Electricity in Queensland</title>
      <meta name="description" content="When you need subdivision construction services, including subdivision street lighting, in Queensland, give Robin Russell &amp; Associates a call." />
    </Helmet>

    <section className="our-goals" role="appWrapper">
      <div className="container">
        <Row>
          <Col xs={12} sm={8}>
            <h1>Subdivision Energex, Ergon, NBN and Street Lighting in Queensland</h1>
            <p>Subdivisions for residential housing and commercial developments need
              subdivision electricity, subdivision street lighting and subdivision NBN
              connections.&nbsp; Robin Russell &amp; Associates can arrange this.
              We are the most experienced subdivision electrical engineers in Queensland,
              and we do more than just provide designs. Our services extend to include
              full on-site contract superintendent services by experienced contract
              supervisors. Plus, we get all materials directly from Energy Queensland
              at the lowest prices.</p>
            <p>We work all over Queensland - either Energex subdivisions or Ergon
              subdivisions. No one will give you better value for money. Read below
              to see more about our services and what we can do for your project.</p>
            <h3 className="text--style-light text--weight-max text--color-default">
              While our design will aim for minimal cost, the service we provide is
              anything but minimal. Our goals are <strong>lowest TOTAL cost of
                design + construction</strong> and <strong>no delays.</strong>
            </h3>
            <p>
              We do not simply churn out basic designs as cheaply as possible.
              Current research indicates that &quot;... poor quality design and
              documentation is estimated to cost 7% of total construction costs,
              with rework in construction likely to be greater than 10% of total
              cost.&quot; (Australia&apos;s Cooperative Research Centre for
              Construction Coordination - QUT). It is cost-effective to invest a
              little more up-front on consultant&apos;s fees in order save a
              much greater amount in construction. We have evidence that the
              total cost of our jobs (design + construction) can be as much as
              20 percent lower than others achieve.
            </p>

            <p>
              That is why we employ only <strong>highly-experienced designers</strong> - their
              average length of experience in subdivision design is over 20
              years. Our full-time <strong>construction co-ordinators</strong> were formerly
              supervisors with subdivision contractors, so they understand
              construction issues thoroughly. Construction co-ordination and
              oversight is a service that most (all?) of our competitors do not
              provide. We prepare detailed materials lists and obtain quotations
              from Energex or Ergon for the direct supply of all materials,
              including cables and transformers. In this way, we save our
              clients about 10% of the cost of materials. We obtain the lowest
              construction costs by competitive tendering - much lower than
              prices usually quoted by design/construct contractors.
            </p>

            <p>
              Finally, we sometimes find it necessary to confront electricity
              authorities or councils when their demands or service to our
              clients are unreasonable. This is where our experience pays off.
              With goodwill on both sides, a satisfactory outcome usually results.
            </p>
          </Col>
          <Col xs={12} sm={4} className="quicklinks">
            <h3 className="animated pulse infinite">Quicklinks</h3>
            <Row>
              <Col xs={5} xsOffset={1} sm={12} smOffset={0}>
                <Link to="/services#telecommunications">
                  Telecommunications
                  <img
                    src={IconQuicklinkTelecommunications}
                    className="icon--quicklink"
                    alt="Telecommunications quicklink"
                  />
                </Link>
              </Col>
              <Col xs={4} sm={12}>
                <Link to="/services#electrical">
                  Electrical
                  <img
                    src={IconQuicklinkElectrical}
                    className="icon--quicklink"
                    alt="Electrical quicklink"
                  />
                </Link>
              </Col>
            </Row>
            <Row>
              <Col xs={5} xsOffset={1} sm={12} smOffset={0}>
                <Link to="/services#street-lighting">
                  Street Lighting
                  <img
                    src={IconQuicklinkStreetLighting}
                    className="icon--quicklink"
                    alt="Street Lighting quicklink"
                  />
                </Link>
              </Col>
              <Col xs={4} sm={12}>
                <Link to="/services#gas">
                  Gas
                  <img
                    src={IconQuicklinkGas}
                    className="icon--quicklink"
                    alt="Gas quicklink"
                  />
                </Link>
              </Col>
              <Col xs={4} sm={12}>
                <Link to="/services#other-services">
                  Monitor Progress
                  <img
                    src={IconQuicklinkOtherServices}
                    className="icon--quicklink"
                    alt="Other Services quicklink"
                  />
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>

    <VerticalCarousel>
      <VerticalCarousel.Cell navTitle="Design">
        <h3>
          <span className="text--color-default">Our process.</span>
          &nbsp;<span className="text--color-beige">1# Design.</span>
        </h3>

        <ul className="list list--spaced">
          <li>Establish minimum requirements of our client, council and Energex/Ergon,</li>
          <li>Prepare a master plan for the entire development (if there will be future stages),</li>
          <li>Consult with the surveyor and the civil engineer,</li>
          <li>Prepare the design of electricity reticulation and street lighting for the stage,</li>
          <li>Submit the design to council (and/or Main Roads Department) for approval,</li>
          <li>Prepare the design of NBN conduits & pits,</li>
          <li>Send the design to the gas company if gas is to be installed,</li>
          <li>Prepare a reliable construction cost estimate for the client,</li>
          <li>
            Negotiate for Energex to contribute the full cost of any additional
            network augmentation which Energex has requested.
          </li>
        </ul>
      </VerticalCarousel.Cell>
      <VerticalCarousel.Cell navTitle="Administration">
        <h3>
          <span className="text--color-default">Our process.</span>
          &nbsp;<span className="text--color-beige">2# Administration.</span>
        </h3>
        <br />
        <p>Necessary administrative activities include:</p>
        <ul className="list list--spaced">
          <li>obtain, check Energex&apos;s/Ergon&apos;s Agreement offer - forward to the client,</li>
          <li>arrange for purchase of materials by the client direct from Energex/Ergon - the cheapest way to buy materials,</li>
          <li>obtain Energex&apos;s/Ergon&apos;s Certificate for Electricity Supply for plan sealing,</li>
          <li>obtain the Agreement from NBN,</li>
          <li>generate an RPEQ certificate for telecommunications for plan sealing.</li>
        </ul>
      </VerticalCarousel.Cell>
      <VerticalCarousel.Cell navTitle="Construction">
        <h3>
          <span className="text--color-default">Our process.</span>
          &nbsp;<span className="text--color-beige">3# Construction.</span>
        </h3>
        <br />
        <p>Our Contract Superintendent services in accordance with AS 4000-1997 include:</p>
        <ul className="list list--spaced">
          <li>prepare a detailed contract specification, based on AS4000-1997,</li>
          <li>call tenders from three or more Energex-accredited contractors,</li>
          <li>engage the contractor selected by our client,</li>
          <li>field-based Construction Co-ordinators to supervise the contractor (no one else does this),</li>
          <li>co-ordinate all activities necessary to ensure prompt commencement and completion of construction work,</li>
          <li>investigate and resolve construction issues fairly and promptly,</li>
          <li>check and certify the contractor&apos;s progress claims and any variations requests.</li>
        </ul>
        <p>Unlike some others, we have no special working relationship with any
          particular contractor. All dealings are transparent and at arm’s length.
          So when we call tenders, you are assured of the most competitive prices.
        </p>
      </VerticalCarousel.Cell>
      <VerticalCarousel.Cell navTitle="Final Wrap-Up">
        <h3>
          <span className="text--color-default">Our process.</span>
          &nbsp;<span className="text--color-beige">4# Final Wrap-Up.</span>
        </h3>
        <br />
        <p>In order to get the new installation transferred across to Energex/Ergon and the bond released without delay, we:</p>
        <ul className="list list--spaced">
          <li>ensure that correct documentation is submitted promptly to Energex by the contractor,</li>
          <li>monitor the process of defect reporting and rectification, to prevent delays by Energex or the contractor,</li>
          <li>follow up Energex for issue of their Certificate of Acceptance.</li>
        </ul>
      </VerticalCarousel.Cell>
    </VerticalCarousel>

    <section className="services-description text-center">
      <div className="container">
        <Row>
          <Col xs={12} sm={10} smOffset={1}>
            <h3>
              For more than 30 years, Robin Russell &amp; Associates has specialised
              in the design and construction management of subdivision
              electrical services for land developers.  We do nothing else and
              we&apos;re very experienced. Because of our high turnover, we can
              achieve economies of scale that allow us to create added value -
              cheaper materials and lowest tender prices.
            </h3>

            <h3>
              And if you&apos;re not getting a fair deal from the authorities,
              we&apos;ll readily go to bat for you - with a high chance of success.
              That&apos;s where experience counts.
            </h3>
            <br />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Link to="/services#telecommunications">
              <button className="btn btn-robrus btn--color-cascade">
                Check out our services
              </button>
            </Link>
          </Col>
        </Row>
      </div>
    </section>

    <span className="anchor" id="telecommunications"></span>
    <section className="telecommunications">
      <div className="container text--color-inverse">
        <Row className="text-center" data-aos="flip-left">
          <img src={IconSectionTelecommunications} className="icon--section" alt="Telecommunications section" />
          <h3 className="text--color-inverse service-title">Telecommunications</h3>
        </Row>

        <p data-aos="zoom-in-up">
          <strong>
            <p>Council development approvals require the provision of telephone and broadband services to all lots. In addition, the Telecommunications Act 1997 requires developers to provide fibre-ready conduit installations to all lots. The developer must first decide which carrier will provide the subdivision&apos;s telecommunications services. All options have their pros and cons.  If requested, we can provide advice along those lines.
            </p>
            <p>
              It&apos;s our role to design and arrange installation of the conduits and pits. However the developer signs an Agreement with the chosen carrier for installation of fibre in those conduits.
            </p>
          </strong>
        </p>

        <Row className="row--grid" data-aos="zoom-in-up">
          <Col xs={12} sm={3}>
            <strong className="text-uppercase">NBN OR OTHER CARRIER</strong>
          </Col>

          <Col xs={12} sm={9}>
            <p>
              By far the great majority of subdivisions are serviced by NBN, however the developer does have the further option of engaging one of the smaller telecommunications providers, such as Opticomm. In that case, the selected carrier will usually design and install the conduits - at the developer&apos;s expense of course.
            </p>
            <br />
            <br />
          </Col>
        </Row>

        <Row className="text-center">
          <Col xs={12} sm={2} smOffset={5}>
            <Link to="/services#electrical">
              <button className="btn btn-robrus btn-fullwidth btn--color-inverse">
                Next
              </button>
            </Link>
          </Col>
        </Row>
      </div>
    </section>

    <span className="anchor" id="electrical"></span>
    <section className="electrical">
      <div className="container">
        <Row className="text-center" data-aos="slide-up">
          <img src={IconSectionElectrical} className="icon--section" alt="Electrical section" />
          <h3 className="text--color-default service-title">Electrical</h3>
        </Row>

        <p data-aos="flip-right">
          <strong>
            In SE Queensland (Energex&apos;s area), subdivision electricity is
            contestable - the developer&apos;s chosen consultant and contractor
            can design and construct it. In regional Queensland (Ergon&apos;s
            area), due to union concerns, only a limited amount of underground
            extension is contestable. The rest - overhead, external and
            commissioning - can only be done by Ergon - at Ergon&apos;s prices.
          </strong>
        </p>

        <Row data-aos="flip-right">
          <Col xs={12} sm={6}>
            <ul className="list list--spaced">
              <li>
                Our designs are of the highest standard, to make the
                contractor&apos;s work as simple and risk-free as possible.
                This attracts the lowest tender prices.
              </li>
              <li>
                When we have completed the design, we provide a reliable cost
                estimate for construction.
              </li>
              <li>
                We negotiate with Energex or Ergon for them to bear the full
                cost of any additional work they request. Sometimes this saves
                our clients thousands of dollars.
              </li>
              <li>
                We obtain Energex&apos;s Agreement offer, check it and send it
                to our client with our recommendations.
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={6}>
            <p className="margin--top-30">
              At that point, most consultants in this field have finished their
              work. We go beyond design, however, to the management of
              construction. We provide full Contract Superintendent service to
              AS 4000:1997. This involves calling tenders, recommending a
              contractor, engaging the contractor selected by our client and
              administering the contract. We have full-time Construction
              Co-ordinators in the field, who have a wealth of relevant
              construction experience. Their main responsibility is to get
              construction started and finished on time, to ensure that jobs
              don&apos;t run late. When problems arise during construction,
              they can get onto finding solutions fast.
            </p>

            <p>
              We run a contractor-rating scheme, based on contractors time
              performance. As a result, contractors give us their very best
              service. We get jobs finished and clients bonds released in the
              shortest possible time.
            </p>
            <br />
            <br />
          </Col>
        </Row>

        <Row className="text-center">
          <Col xs={12} sm={2} smOffset={5}>
            <Link to="/services#street-lighting">
              <button className="btn btn-robrus btn-fullwidth btn--color-cascade">
                Next
              </button>
            </Link>
          </Col>
        </Row>
      </div>
    </section>

    <span className="anchor" id="street-lighting"></span>
    <section className="street-lighting">
      <div className="container">
        <Row className="text-center" data-aos="flip-left">
          <img src={IconSectionStreetLighting} className="icon--section" alt="Street Lighting section" />
          <h3 className="text--color-inverse service-title">Street Lighting</h3>
        </Row>

        <p className="text--color-inverse" data-aos="zoom-in-up">
          <strong>
            Technically, the design of street lighting is probably our greatest challenge. Each council has its own special requirements. Now LED street lights have been adopted universally and the range of lights has increased significantly.
          </strong>
        </p>

        <ul className="list list--spaced text--color-inverse" data-aos="zoom-in-up">
          <li>
            Historically, most street lights are owned and operated by either Energex or Ergon, at councils&apos; expense. Councils nominate the levels of lighting required, and to some extent, the type of street lights installed. Two councils - Ipswich and Sunshine Coast - have even taken over ownership of the street lights.
          </li>
          <li>
            Developers have a limited choice of street lights they can specify. Generally, these can only be selected from the types and colours stocked by Energex or Ergon. In Ipswich or Sunshine Coast, only the types of lights approved by council can be installed
          </li>
          <li>
            Our objective, when designing street lighting, is to satisfy developers and council requirements, at the lowest possible cost.
          </li>
        </ul>

        <p className="text--color-inverse" data-aos="zoom-in-up">
          <strong>What sort of street lights can I install?</strong> Check out our Street Lighting FAQ section <a href="/faq"><strong>here</strong></a> for more information.
        </p>

        <br />
        <Row className="text-center">
          <Col xs={12} sm={2} smOffset={5}>
            <Link to="/services#other-services">
              <button className="btn btn-robrus btn-fullwidth btn--color-cascade">
                Next
              </button>
            </Link>
          </Col>
        </Row>
      </div>
    </section>

    <span className="anchor" id="gas"></span>
    <section className="other-services">
      <div className="container">

        <div className="text-center" data-aos="slide-up">
          <p>
            <i className="fa fa-fire fa-3x" aria-hidden="true"></i>
          </p>
          <h3 className="text--color-default service-title">Gas</h3>
        </div>

        <Row className="row--grid" data-aos="flip-right">
          <Col xs={12} sm={3}>
            <strong className="text-uppercase">Co-ordination with gas</strong>
          </Col>

          <Col xs={12} sm={9}>
            <p>
              Installation of gas reticulation may be attractive if the
              subdivision is near existing gas mains. The developer can easily
              find this out by contacting APA. If it is feasible, APA will be
              pleased to make an offer, which may include the installation of gas
              mains at little or no cost to the developer. When gas is to be
              installed, we send the electrical design to APA so they can
              determine where they can share the electricity trench. Where they
              can&apos;t share the trench, we will usually arrange for the electrical
              contractor to excavate a separate trench for them. We co-ordinate
              the installation of gas and electricity at the same time.
            </p>

            <ul className="list list--spaced">
              <li>
                Co-ordinate electrical design with APA&apos;s design, to allow
                shared trenches where permissible.
              </li>
              <li>Specify shared or separate gas trenches in electrical contract.</li>
              <li>Co-ordinate electrical/gas installation.</li>
            </ul>
            <br />
          </Col>
        </Row>

      </div>
    </section>

    <span className="anchor" id="other-services"></span>
    <section className="other-services">
      <div className="container">

        <div className="text-center" data-aos="slide-up">
          <p>
            <i className="fa fa-user fa-3x" aria-hidden="true"></i>
            <i className="fa fa-fire fa-3x" aria-hidden="true"></i>
            <i className="fa fa-plus fa-3x" aria-hidden="true"></i>
          </p>
          <h3 className="text--color-default service-title">Monitor Progress</h3>
        </div>

        <Row className="row--grid" data-aos="flip-right">
          <Col xs={12} sm={3}>
            <strong className="text-uppercase">Large Customer Connections</strong>
          </Col>

          <Col xs={12} sm={9}>
            <p>
              The Australian Energy Regulator (AER) determined that the design
              and construction of electrical connection assets which Large
              Customers must pay for should be competitive. Large Customers
              are defined as follows:
            </p>
            <ul className="list list--spaced">
              <li>Estimated annual consumption greater than 4GWh, or</li>
              <li>estimated maximum demand greater than 1MVA, or</li>
              <li>a generating system rated at more than 30kVA.</li>
            </ul>
            <p>
              An application for one of these Large Customer Connections (LCCs)
              has the option of either engaging Energex/Ergon to design and
              construct the substation, or engaging their own consultant and
              contractor. Details of the process are found on Energex&apos;s
              website <a href="https://www.energex.com.au/home/our-services/connections/major-business/manuals-and-fact-sheets" target="_blank">here</a>.
              RRA has the experience to handle Large Customer Connections of all
              sizes.  In particular, we can offer the following advantages:
            </p>
            <ul className="list list--spaced">
              <li>Our experience and  intimate knowledge of the industry ensures
                that jobs don’t get bogged down when complex issues arise (as
                they tend to do with LCC jobs);</li>
              <li>we arrange for  our clients to purchase all electrical
                materials direct from the cheapest source - Energex, with no
                mark-ups The saving is significant – usually around 10 percent;</li>
              <li>we have no ties to any particular contractor, so our tender
                invitations attract the most competitive prices.</li>
            </ul>
            <br />
          </Col>
        </Row>

        <Row className="row--grid" data-aos="flip-right">
          <Col xs={12} sm={3}>
            <strong className="text-uppercase">On-line access to job progress</strong>
          </Col>

          <Col xs={12} sm={9}>
            <p>
              Here you can access project design and construction progress
              updates, and information such as key personnel engaged on the
              project. Please <Link to="/contact-us">contact us</Link> to register
              for this online service.
            </p>
          </Col>
        </Row>
      </div>
    </section>
  </AppWrapper>
)};

const carouselBannerLeft = (
  <h2 className="text--color-inverse banner-title">
    Our Services
  </h2>
);

const carouselBannerRight = (
  <div className="info-block">
    <h3 className="text--color-inverse">FAQ&apos;s + Services Info</h3>

    <hr className="divider divider--size-lg divider--spacing-sm divider--color-gray" />

    <p>
      Developer&apos;s common questions answered here.
    </p>
    <Link to="/faq" className="carousel-news-link text--style-medium">
      Read more +
    </Link>
  </div>
);

export default Services;
