import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import kebabCase from "lodash/kebabCase";

import RobinRussellLogoImg from "../images/robin-russell-logo_tmp.png";
// import SearchIcon from './../images/search-icon_tmp.png';

import menuNavigation from "./navigation.json";

const Header = (props) => {
  const navigate = useNavigate();
  
  const [isHeaderMinimized, setIsHeaderMinimized] = useState(false);

  const { currentPath } = props;

  useEffect(() => {
    document.onscroll = (e) =>
      handlePageScroll(e.srcElement.documentElement.scrollTop);
  });

  const handlePageScroll = (scrollTop) => {
    const temp = scrollTop > 60;
    if (temp !== isHeaderMinimized) {
      setIsHeaderMinimized(temp);
    }
  };

  return (
    <header
      className={`header-robrus navbar-fixed-top${
        isHeaderMinimized ? " header-minimized" : ""
      }`}
    >
      <div className="container">
        <div className="extra-content">
          {/* NOTE: Search Hidden for Phase I */}
          {/*
            <div className="search">
              <input
                type="text"
                className="text--color-inverse text--style-medium"
                placeholder="Search site"
              />
              <button><img src={SearchIcon} alt="Search across site" /></button>
            </div>
            <hr className="divider-vertical" />
            */}
          {/* NOTE: Login Hidden until new protal built */}
          {/* <Link to="/login"> */}
          {/* <a href="http://portal.robrus.com.au/login" target="_self">
              <button className="btn btn-robrus btn--color-beige btn-filled btn-sm">
                Portal login
              </button>
            </a>
          */}
        </div>
      </div>
      <Navbar componentClass="navbar-robrus" collapseOnSelect fixedTop>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/">
              <img
                className="brand-logo"
                src={RobinRussellLogoImg}
                alt="Robin Russell & Associated PTY. LTD."
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight activeHref={currentPath}>
            {menuNavigation.map((navItem, index) => {
              return (
                <LinkContainer
                  to={navItem.path}
                  key={`navitem-${kebabCase(navItem.path)}`}
                >
                  <NavItem eventKey={index} onClick={() => {navigate(navItem.path);
                  window.scrollTo(0,0)}}>
                    {navItem.name}
                  </NavItem>
                </LinkContainer>
              );
            })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

Header.propTypes = {
  currentPath: PropTypes.string.isRequired,
};

export default Header;
