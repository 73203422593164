import React from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import AppWrapper from '../AppWrapper';

import './styles.scss'

const NotFoundPage = () => {

  return (<AppWrapper className="not-found-page" currentPath="*">

    <Helmet>
      <title>Robin Russell &amp; Associates | Not Found Page</title>
      <meta name="description" content="Subdivision construction services - Queensland" />
    </Helmet>

    <div className="container" role="appWrapper">
      <h1>
        404 Page Error
      </h1>
      <p>
        <span>Sorry, we seem to have lost this page. Return to </span>
        <Link to="/">homepage</Link>
      </p>
    </div>
  </AppWrapper>
)
};

export default NotFoundPage;
