import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const TruncatedContent = ({ maxLength, text, content, linkTo, onClick }) => {
  const trancateContent = (content.length > maxLength)
    ? content.slice(0, maxLength)
    : content;

  // const isLastSymbolSpace = trancateContent.slice(-1) === ' ';

  return (
    <div className="truncated-content">
      {trancateContent}
      {(content.length > maxLength) && getReadMoreButton(text, linkTo, onClick)}
    </div>
  );
};

const getReadMoreButton = (text, linkTo, onClick) => (
  <span>
    ...&nbsp;
    {
      linkTo
        ? (
          <Link to={linkTo}>{text}</Link>
        )
        : (
          <button className="text--color-beige" onClick={onClick}>
            {text}
          </button>
        )
    }
  </span>
);

TruncatedContent.propTypes = {
  maxLength: PropTypes.number, // eslint-disable-line react/require-default-props
  text: PropTypes.string, // eslint-disable-line react/require-default-props
  content: PropTypes.string, // eslint-disable-line react/require-default-props
  linkTo: PropTypes.string, // eslint-disable-line react/require-default-props
  onClick: PropTypes.func, // eslint-disable-line react/require-default-props
};

export default TruncatedContent;
