import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_SPACE_ID = process.env.REACT_APP_API_SPACE_ID;
const API_TOKEN = process.env.REACT_APP_API_TOKEN;

export const getFaqData = async () => {
    const result = await axios.get(`${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_TOKEN}&content_type=faqCategories`);
    if(result.data) {
        return result.data;
    } else {
        return [];
    }
}

export const getFetchPostsData = async () => {
    const result = await axios.get(`${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_TOKEN}&content_type=2wKn6yEnZewu2SCCkus4as&order=-fields.date`);
    
    if(result.data) {
        return result.data;
    } else {
        return {};
    }
}

export const getFetchLastData = async () => {
    const result = await axios.get(`${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_TOKEN}&content_type=2wKn6yEnZewu2SCCkus4as&fields.date[gte]=2017-01-01&order=-fields.date`);
    if(result.data) {
        return result.data;
    } else {
        return [];
    }
}

export const getFetchPrewData = async () => {
    const result = await axios.get(`${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_TOKEN}&content_type=2wKn6yEnZewu2SCCkus4as&fields.date[lt]=2017-01-01`);
    if(result.data) {
        return result.data;
    } else {
        return [];
    }
}
export const getFetchNewsWidgetData = async () => {
    const result = await axios.get(`${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_TOKEN}&content_type=2wKn6yEnZewu2SCCkus4as&fields.date[gte]=2017-01-01&limit=3&order=-fields.date`);
    if(result.data) {
        return result.data;
    } else {
        return [];
    }
}