import React from 'react';
import { Row, Col } from 'react-bootstrap';

import BackToTopImg from './../images/back-to-top_tmp.png';

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <Row>
        <Col xs={12} sm={6}>
          <div className="contact-info">
            <h5 className="text-uppercase">Contact us</h5>

            <ul>
              <li><strong>p.</strong> <a href="tel:0738725555">(07) 3872-5555</a></li>
              <li><strong>f.</strong> <a href="tel:0738725566">(07) 3872-5566</a></li>
              <li><strong>e.</strong>
                &nbsp;<a href="mailto:rr@robrus.com.au">rr@robrus.com.au</a>
              </li>
            </ul>

            <ul>
              <li><strong>abn.</strong> 78 010 589 661</li>
              <li><strong>rpeq.</strong> 1546 (Robin Russell)</li>
            </ul>
          </div>
        </Col>

        <Col xs={12} sm={6}>
          <div className="copyright text--style-medium">
            <ul className="rights">
              <li>Copyright © {new Date().getFullYear()}.</li>
              <li>Robin Russell & Associates. All rights reserved.</li>
            </ul>
            <button className="scroll-top" onClick={() => window.scrollTo(0, 0)}>
              <img src={BackToTopImg} alt="Scroll page to top" />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  </footer>
);

export default Footer;
