import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import kebabCase from 'lodash/kebabCase';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';

import TruncatedContent from '../../components/TruncatedContent';

import './styles.scss';

const Article = ({ title, body, date, minimal, preview }) => (
  <section className="article">
    <div className={(minimal || preview) && 'highlighted'}>

      {
        (minimal || preview)
          ? renderMinimalArticle(title, date, body, preview)
          : renderFullArticle(title, date, body)
      }
    </div>

    {
      (minimal || preview) && (
        <hr className="divider divider--spacing-md" />
      )
    }
  </section>
);

const renderMinimalArticle = (title, date, body, preview) => {
  const year = new Date(date).getFullYear();
  const articleUrl = `/news/${year}/${kebabCase(title)}`;

  return (
    <div className="article--content">
      <h5 className={`content-title${!preview ? '--fixed-height' : ''}`}>
        <Link className="text--color-default text-uppercase" to={articleUrl}>
          {title}
        </Link>
      </h5>

      {
        preview && (
          <TruncatedContent
            maxLength={425}
            text="read more +"
            content={body}
            linkTo={articleUrl}
          />
        )
      }

      <p className="text--color-cascade">{moment(date, 'DD MMMM YYYY').toString()}</p>
    </div>
  );
};

const renderFullArticle = (title, date, body) => (
  <div className="article--content">
    <h3 className="content-title text--style-medium text--color-default">
      {title}
    </h3>
    <hr className="divider divider--spacing-md" />

    <p className="text--color-cascade">{moment(date, 'DD MMMM YYYY').toString()}</p>
    <br />

    <div className="major">
      <ReactMarkdown>{body}</ReactMarkdown>
    </div>

    <div className="content-navigation">
      <Link to="/news">
        <button className="btn btn-robrus btn--color-cascade">
          Back to all news
        </button>
      </Link>
    </div>
  </div>
);

Article.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string, // eslint-disable-line react/require-default-props
  date: PropTypes.string.isRequired,

  minimal: PropTypes.bool, // eslint-disable-line react/require-default-props
  preview: PropTypes.bool, // eslint-disable-line react/require-default-props
};

export default Article;
