import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import AppWrapper from '../AppWrapper';
import CardRobRus from '../../components/CardRobRus';

import IconServices1 from '../../assets/icon-telecommunication_tmp.png';
import IconServices2 from '../../assets/icon-electrical_tmp.png';
import IconServices3 from '../../assets/icon-street-lighting_tmp.png';

const WhyChooseUs = () => (
  <AppWrapper
    className="why-choose-us-page"
    currentPath="why-choose-us"

    carousel
    bannersLeft={[carouselBannerLeft]}
  >

    <Helmet>
      <title>Robin Russell &amp; Associates | Why Choose Us</title>
      <meta name="description" content="Subdivision construction services - Queensland" />
    </Helmet>

    <div className="container">
      <section className="whychooseus-info">
        <Row>
          <Col xs={12}>

            <h3 className="text--style-light text--weight-max text--color-default">
              Unmatched experience, lowest cost, construction co-ordination,
              + added value.
            </h3>
            <p>
              <strong>Unmatched Experience.</strong> Our Principal, Robin Russell,
              has the longest professional
              experience of any engineer in the Queensland electricity
              supply industry. Our
              Designers average over 20 years experience in design of
              electricity reticulation and street lighting. Our Construction
              Co-ordinators in the field each have over 30 years&apos;
              experience in electrical construction management. We have
              completed more than 9000 subdivisions in the past 30 years.
              We have been told that we process more NBN subdivisions than
              any other consultant in Australia.
            </p>
            <p>
              <strong>Lowest Cost.</strong> We negotiate for Energex/Ergon
              to bear the full cost of any additional non-standard
              requirements. We arrange for clients to purchase all materials
              direct from Energex/Ergon, saving 10% or more. Low-risk designs
              and a comprehensive specification attract low tender prices
              Competitive tendering secures the best market rates -
              significantly lower than design/construct prices. We bear the
              full cost of our mistakes.
              communities.
            </p>
            <p>
              <strong>On-site Construction Co-ordination.</strong> Full Contract
              Superintendent service to AS 4000-1997. Site access dates and
              contract times managed by field-based Construction Co-ordinators.
              Construction Co-ordinators make site inspections, check all variations
              claims and address construction issues immediately.
            </p>
            <p>
              <strong>Added Value.</strong> Registered professional
              engineers. Detailed knowledge of Energex/Ergon organization
              and procedures. We actively pursue clients&apos; interests
              with Energex/Ergon and councils, to ensure fair dealings.
              Clients have on-line access to individual job progress and
              current cost estimates.
            </p>
          </Col>
        </Row>
      </section>
    </div>

    <CardRobRus className="our-services text-center">
      <h3>
        Our Services.
      </h3>
      <p className="description" data-aos="fade-in">
        For more than 30 years, Robin Russell &amp; Associates has specialised
        in the design and construction management of subdivision electrical
        services for land developers. We do nothing else and we&apos;re very
        experienced. Because of our high turnover, we can achieve economies
        of scale that allow us to create added value - cheaper materials and
        lowest tender prices. And if you&apos;re not getting a fair deal from the
        authorities, we&apos;ll readily go to bat for you - with a high chance of
        success. That&apos;s where experience counts.
      </p>
      <Row className="row--grid services-description row--col-spaced--md">
        <Col sm={4} className="text-center animated zoomIn">
          <img src={IconServices2} className="service-icon" alt="Electrical" data-aos="fade-in" />
          <h4 className="description-title" data-aos="fade-in">Electrical</h4>
          <p data-aos="fade-in">
            Developers must install electricity reticulation that meets the
            specifications of Energex/Ergon, the eventual owner of those
            assets. So the challenge is to know exactly what those
            specifications are.  They are very complex and there is no room
            for error. We have the experience to ensure that our designs are
            compliant, without the waste of costly overdesign.  Then we go
            further - we compile full materials lists, so that our clients
            can purchase all materials directly from Energex/Ergon, at a
            discounted rate. Contractors are selected by competitive
            tendering, to secure the lowest construction cost.
          </p>
          <Link to="/services#electrical" data-aos="fade-in">Read more +</Link>
        </Col>

        <Col sm={4} className="text-center animated zoomIn">
          <img src={IconServices3} className="service-icon" alt="Street Lighting" data-aos="fade-in" />
          <h4 className="description-title" data-aos="fade-in">Street Lighting</h4>
          <p data-aos="fade-in">
            At present, most street lights are owned by Energex/Ergon, but
            councils are becoming increasingly interested in assuming
            ownership, as part of their &quot;smart city&quot; drive. Most
            councils now want LED street lights in new subdivisions and
            electricity authorities and suppliers are struggling to keep up
            with demand. We strongly support the move to LED street lighting
            and have taken several key initiatives to speed this up and
            facilitate the forthcoming change of ownership of street lights.
          </p>
          <Link to="/services#street-lighting" data-aos="fade-in">Read more +</Link>
        </Col>

        <Col sm={4} className="text-center animated zoomIn">
          <img src={IconServices1} className="service-icon" alt="Telecommunications" data-aos="fade-in" />
          <h4 className="description-title" data-aos="fade-in">Telecommunications</h4>
          <p data-aos="fade-in">
            We organize the installation of conduits and pits for NBN or
            Telstra.  Close co-ordination with the electrical layout is
            required to ensure that pits are not installed in the path of
            future driveways.
          </p>
          <Link to="/services#telecommunications" data-aos="fade-in">Read more +</Link>
        </Col>
      </Row>
    </CardRobRus>

  </AppWrapper>
);

const carouselBannerLeft = (
  <h2 className="text--color-inverse banner-title">
    Why Choose Us?
  </h2>
);

export default WhyChooseUs;
