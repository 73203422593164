import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import CollapseElement from '../../components/CollapseElement';

const CatItems = ({ itemsData }) => (
  <section className="collapses">
    {
      itemsData.map((item) =>{ 
        function createMarkup() { return {__html: item.content}; };
        return (
        <CollapseElement key={item.id} title={item.title} defaultActive={item.defaultActive} anchorLink={item.anchorLink}>
          <div>
          <div dangerouslySetInnerHTML={createMarkup()} />
          </div>
        </CollapseElement>
      )})
    }
  </section>
);

/*
//Collapse categories
*/

const FaqItems = ({ currentData }) => {
  return (
  <div className="container">
    { currentData.categories?
      currentData.categories.map((cat) => (
        <section key={cat.id}>
          <Row>
            <Col sm={12} md={4}>
              <h4 className="title text--style-medium text--weight-min text--color-default">
                {cat.categoryName}
              </h4>
            </Col>
            <Col sm={12} md={8}>
              <CatItems itemsData={cat.items} />
            </Col>
          </Row>
        </section>
      )):""
    }
  </div>
)};

CatItems.propTypes = {
  itemsData: PropTypes.array.isRequired,
};

CatItems.defaultProps = {
  itemsData: [],
};

FaqItems.propTypes = {
  currentData: PropTypes.object.isRequired,
};

FaqItems.defaultProps = {
  currentData: [],
};

export default FaqItems;
